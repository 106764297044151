.orderDetail .orderStatus {
  font-weight: 800;
  font-size: 16px;
  margin-left: 10px;
}
.orderDetail .order_err {
  padding: 10px;
  background-color: #7f7f7f;
  color: #fff;
}
.orderDetail .order_success {
  padding: 10px;
  background-color: #70b602;
  color: #fff;
}
.orderDetail .order_form {
  margin-top: 15px;
}
.orderDetail .order_form div.df {
  padding: 8px 0;
}
.orderDetail .order_form div.df span:first-child {
  width: 6rem;
  text-align: right;
  margin-right: 8px;
}
.divider {
  margin: 12px 0;
}
.orderBox div {
  width: 50%;
  align-items: center;
}
