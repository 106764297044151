


































































































































































































































































































































































































































































































































.orderDetail {
  .orderStatus {
    font-weight: 800;
    font-size: 16px;
    margin-left: 10px;
  }
  .order_err {
    padding: 10px;
    background-color: #7f7f7f;
    // border: 1px solid rgb(255, 204, 0);
    color: #fff;
  }
  .order_success {
    padding: 10px;
    background-color: #70b602;
    // border: 1px solid rgb(255, 204, 0);
    color: #fff;
  }
  .order_form {
    margin-top: 15px;
    div.df {
      padding: 8px 0;
      span:first-child {
        width: 6rem;
        text-align: right;
        margin-right: 8px;
      }
    }
  }
}
.divider {
  margin: 12px 0;
}
.orderBox {
  div {
    width: 50%;
    align-items: center;
  }
}
